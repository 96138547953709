import React from 'react'
import {FormControl, Select, MenuItem, InputLabel,} from '@material-ui/core';

function SelectList({lista,value,onChange,etiq,etqVal,etqId,etqAlias,estilo,nameEtq,tipo}) {
  return (
    <FormControl  style={estilo} size="small" variant={tipo??"standard"}> 
    <InputLabel id={`${etiq}IdLabel`} size="small">{`${etiq}`}</InputLabel>
    <Select  label={`${etiq}`} id={`${etiq}Id`} name={`${nameEtq}`}
        value={value} onChange={onChange}  
        size="small"
    >
    {lista.map((lugar) => {
        if(lugar[`${etqId}`]>0) {
        return(
        <MenuItem size="small" value={lugar[`${etqVal}`]} key={lugar[`${etqId}`]}>
            <em>{lugar[`${etqAlias}`] }</em>
        </MenuItem>
        )}
    } )}     
    </Select>
</FormControl>     
  )
}

export default SelectList