import React, { useEffect, useState } from 'react';
import { Stage, Layer, Image} from 'react-konva';
import useImage from "use-image";
import TextoContenedor from './TextoContenedor'
  
export default function ContenedorImg({url,img,color1,color2,color3,stageRef,texto1,texto2,texto3}){
  const [idSelec, setIdSelec] = useState(-1);
  const [suspen,setSuspen]= useState({susTxt1:false,susTxt2:false,susTxt3:true,});
  
  const getImagen = (imagen) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks    
    let [img] = useImage(imagen,'anonymous' );    
    //console.log("adentro del contenedor img");
    return( 
      <Image image={img} width={350}  height={350} />
    );
  };

  return(
  <Stage id={'containerImg'} width={350} height={350}
    style={{ border: '1px solid grey',backgroundColor:'white',width:350,height:350}}
    ref={stageRef} scaleX={1} scaleY={1}  
    onClick={(e) => {
    if (e.currentTarget._id+1 === e.target._id) {
      setIdSelec(-1);
    }
    }}
  >
  <Layer>
    {getImagen(url)}        
    {suspen.susTxt1 ? 
      <div /* onDragEnd={(e) => console.log(e)} */ draggable={true}    >
      <TextoContenedor x={0} y={0} id={'txt1'} idSelec={1} seleccionado={idSelec}
        color={color1} texto={texto1}
        onTextClick={(newSelected) => { setIdSelec(newSelected); }}
      />
      </div>  
    :null }
    {suspen.susTxt2 ? 
    <div  draggable={true}    >
      <TextoContenedor x={100} y={100} id={'txt2'} idSelec={2} seleccionado={idSelec}
        color={color2} texto={texto2}
        onTextClick={(newSelected) => { setIdSelec(newSelected); }}
      />
    </div>
    :null }
    {suspen.susTxt3 ? 
    <div  draggable={true}    >
      <TextoContenedor x={110} y={296} id={'txt3'} idSelec={3} seleccionado={idSelec}
        color={color3} texto={texto3}
        onTextClick={(newSelected) => { setIdSelec(newSelected); }}
      />
    </div>
    :null }
  </Layer>
  </Stage>
  )
}