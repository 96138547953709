import React,{useState,useEffect} from 'react';
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
        Card, Box, Tooltip,IconButton, Typography} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import NumberFormat from 'react-number-format';
const useStyles = makeStyles({   
  estiloCard:{
    width: '35rem',
    boxShadow:'0 0.5em 1em -0.125em rgb(10 10 10 / 10%), 0 0 0 1px rgb(10 10 10 / 2%)',
    // color:'#4a4a4a',
    display:'block',
    padding:'.5rem',
    maxHeight:'100%',
    minHeight: 280,
    marginRight:'.5rem'
  },  
  tablaContainer: {
    width: '34rem',
    maxHeight: '25.5rem',
    minHeight: 280,
    //minWidth:'100%',
  },
  tituloEstilo:{
    paddingBottom:'.25rem',
    fontWeight:'bold'
  },
  tableRow: {
    "&$selected, &$selected:hover": {
      backgroundColor:process.env.REACT_APP_Color_Second
    }
  },
  tableCell: {
    "$selected &": {
      color: "white"
    }
  },
  hover: {},
  selected: {}
});


//const alturaCont = window.innerHeight<937 ? window.innerHeight-250 : window.innerHeight-270

export default function TablaMun({listAvMun,registro, setRegistro,registroEdo,listAvEdo,tipoTabla, funcionRegresar}){    
  const classes = useStyles();      
  const [datos,setDatos] = useState([]);     
  const [totalGen,setTotalGen] = useState([]);     
  const auxCol=process.env.REACT_APP_Fondo_Color  
  useEffect(()=>{    
    
    if (tipoTabla===2) 
    {
      if (listAvMun.length!==0 &&listAvMun[0].avc_gral&& listAvMun[0].avc_gral[0].EdoId!==0) 
      { /* let aux={totHom:0, GenX:0, totMuj:0, avGen:0, MetaGen:0}
        listAvMun[0].avc_gral.forEach(element => {
          aux={ totHom: aux.totHom + element.GenH,
            totMuj: aux.totMuj + element.GenM,
            GenX: aux.GenX + element.GenX,
            avGen: aux.avGen + element.Avc,
            MetaGen: aux.MetaGen + element.Meta
          }
        });
        setDatos(listAvMun[0].avc_gral)  
        setTotalGen(aux) */
        obtenTotales(listAvMun[0].avc_gral)
      }
      else
      { setDatos([])
      }  
    }
    else
    { if (listAvEdo.length!==0 &&listAvEdo[0].avc_gral&& listAvEdo[0].avc_gral[0].EdoId!==0) 
      { /* let aux={totHom:0, GenX:0, totMuj:0, avGen:0, MetaGen:0}
        listAvEdo[0].avc_gral.forEach(element => {
          aux={ totHom: aux.totHom + element.GenH,
            totMuj: aux.totMuj + element.GenM,
            GenX: aux.GenX + element.GenX,
            avGen: aux.avGen + element.Avc,
            MetaGen: aux.MetaGen + element.Meta,}
        });
        setDatos(listAvEdo[0].avc_gral)  
        setTotalGen(aux) */
        obtenTotales(listAvEdo[0].avc_gral)
      }
      else
      { setDatos([])
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[listAvMun,tipoTabla])          

  const obtenTotales=(auxArr)=>{
    let aux={totHom:0, GenX:0, totMuj:0, avGen:0, MetaGen:0}
    auxArr.forEach(element => {
      aux={ totHom: aux.totHom + element.GenH,
        totMuj: aux.totMuj + element.GenM,
        GenX: aux.GenX + element.GenX,
        avGen: aux.avGen + element.Avc,
        MetaGen: aux.MetaGen + element.Meta,}
    });
    setDatos(auxArr)  
    setTotalGen(aux)
  }

  const onChangeTable =(regis)=>{    
    if (tipoTabla===2) {
      setRegistro(regis)  
    }                       
  }

  const celda = (alinear, estilo, contenido,index) => {
    return (
    <TableCell align={alinear} key={index} className={classes.tableCell} 
        padding={'none'} style={estilo} >
        {contenido}
    </TableCell>)
  }

  const CelTitl=({index,titulo,aline})=> celda(aline?aline:"center", {backgroundColor:auxCol,color:'white',}, titulo?titulo:"",index) 

  const colorStatus=(status)=>{
    let stdColor=""
    if (status<25) 
    { stdColor="#E91E63"      
    }
    else
    { if (status<50) 
      {
        stdColor="#f07111"      
      }
      else
      {
        if (status<75) 
        { stdColor="#FBBE06"      
        }
        else
        { stdColor="#19A11E"                        
        }         
      } 
    }
    return(
    <Box display={"flex"}  justifyContent="flex-end" style={{textAlign:"right",alignItems:"center",fontSize:"12px"}}>            
      {`${status.toFixed(2)}%`}
      <FiberManualRecordIcon fontSize="small" style={{color:stdColor,}}/>      
    </Box>)
  }

  function TablaEstr(auxDatos) {        
    
    return(        
    <TableContainer className={classes.tablaContainer} id="tamTablaReg"  >
    <Table stickyHeader aria-label="sticky table">
      <TableHead >    
        { totalGen.length!==0 ?
        <TableRow>
          <CelTitl index={1} titulo={tipoTabla === 2 ? "Municipio":"Estado" } aline={"left"} />         
          <CelTitl index={2} titulo={<span>Meta <br/> <strong>
          <NumberFormat value={totalGen.MetaGen} displayType={'text'}  thousandSeparator={true}/></strong></span> } aline={"center"} />   
          <CelTitl index={3} titulo={<span>Avance <br/><strong><NumberFormat value={totalGen.avGen} displayType={'text'}  thousandSeparator={true}/></strong></span>  } aline={"center"} />                         
          <CelTitl index={4} titulo={"" } aline={"center"} />         
          <CelTitl index={5} titulo={<span>Hombre <strong><NumberFormat value={totalGen.totHom} displayType={'text'}  thousandSeparator={true}/></strong></span>} aline={"center"} />         
          <CelTitl index={6} titulo={<span>Mujer <br/><strong>
          <NumberFormat value={totalGen.totMuj} displayType={'text'}  thousandSeparator={true}/></strong></span>} aline={"center"} />         
          <CelTitl index={7} titulo={<span style={{fontSize:"12px"}}>No Binario <br/> <strong>
          <NumberFormat value={totalGen.GenX} displayType={'text'}  thousandSeparator={true}/></strong></span>} aline={"center"} />                             
        </TableRow>
        :null }                   
      </TableHead>  
      <TableBody>         
      {auxDatos.map((elemnto,index) => {  
        const auxVal=registro?.MunId === elemnto.MunId ?true:false                                              
        return (                  
        <TableRow className={classes.tableRow} selected={ auxVal} 
          classes={{ hover: classes.hover, selected: classes.selected }} 
          key={index+1} onClick={ () => onChangeTable(elemnto) }  
        >   
          {celda("left", {width:"8rem"}, tipoTabla === 2 ? elemnto.MunNom:elemnto.EdoNom ,index+1) }        
          {celda("center", {width:"4rem"}, <NumberFormat value={elemnto.Meta} displayType={'text'}  thousandSeparator={true}/> ,index+2) }
          {celda("right", {width:"4rem"}, <NumberFormat value={elemnto.Avc} displayType={'text'}  thousandSeparator={true}/>  ,index+3) }
          {celda("right", {width:"6rem"}, colorStatus(elemnto.Porcent),index+4) }
          {celda("right", {width:"4rem"},  <NumberFormat value={elemnto.GenH} displayType={'text'}  thousandSeparator={true}/>  ,index+5) }
          {celda("right", {width:"4rem"}, <NumberFormat value={elemnto.GenM} displayType={'text'}  thousandSeparator={true}/>,index+6) }
          {celda("right", {width:"4rem",paddingRight:".3rem"}, <NumberFormat value={elemnto.GenX} displayType={'text'}  thousandSeparator={true}/>,index+7) }     
        </TableRow>              
        );                        
      })}                                                
      </TableBody>
    </Table>
    </TableContainer>        
    ); 
  }
                       
  const llamada= datos.length !== 0 ?(TablaEstr(datos)):(<Box style={{paddingTop:1+'rem'}}>
                                  <Typography variant="h5" component="h5">No contiene datos</Typography>
                                  </Box>)
                                                                                                                                           
  return (
  <Box display={'flex'} flexDirection="row" style={{height: '29rem',marginLeft:"1rem"}}>
    <Card className={classes.estiloCard} style={{height:window.innerHeight-140}}>         
      
      {tipoTabla ===2 ?
        <Box>
         <IconButton onClick={funcionRegresar}><KeyboardBackspaceIcon fontSize="small"/></IconButton>  
          <Typography variant="body1" component="body1">{registroEdo.Num}</Typography> 
        </Box>               
      :null }
      {llamada}   
    </Card>
  </Box>                    
  );
}