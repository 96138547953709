import React,{useState,useEffect} from 'react'
import {AuthContext} from '../context/AuthContext'
import {Box,LinearProgress,TextField, Typography, IconButton,Tooltip} from "@material-ui/core";
import axios from 'axios';
import qs from 'qs';
import { useHistory } from "react-router-dom";
import {llamaApiCargaToken, llamadaApiToken, ErrorGeneral } from '../funciones/ClienteAxios';
import SelectEdo from '../usuario/SelectEdo';
import { obtenDispo } from '../funciones/IdDispositivo';
import SelectList from '../generales/SelectList';
import TablaAgrupamiento from './TablaAgrupa';
import TablaPromovidos from './TablaPromov';
import TablaTotal from './TabEdoTot';
import ReplayIcon from '@material-ui/icons/Replay';
import Graficas from './Graficas';
import { toast } from 'react-toastify'; 

const RepoSudoku = () => {
  let history = useHistory();    
  const colFondo=process.env.REACT_APP_Fondo_Color
  const source = axios.CancelToken.source();  
  const usu= localStorage.getItem('UsuarioNom');
  const nvl= localStorage.getItem('Tipo');
  const usuID = localStorage.getItem('UsuId') ? parseInt(localStorage.getItem('UsuId')) : 0;
  const auxDispV =obtenDispo()
  const {usuario,guardarUsuario}=React.useContext(AuthContext);  
  const [datos,setDatos]= useState([])
  const [espera,setEspera]= useState(false)
  const [esperaList,setEsperaList]= useState(false)
  const [esperaMuni,setEsperaMuni]= useState(false)
  const [esperaSecc,setEsperaSecc]= useState(false)
  const [listMuni, setListMuni] = useState([]);
  const[listSecc, setListSecc] = useState([])    
  const[listManzana, setListManzana] = useState([])    
  const[listSudoDet, setListSudoDet] = useState([])
  const[listTotal, setListTotal] = useState([])    
  const [regisAgrup, setRegisAgrup] = useState([]);
  const [regisTot, setRegisTot] = useState([]);
  const [tituloDirecc, setTituloDirecc] = useState("");
  const [filtros,setFiltros]= useState({edo:15, muni:'', Seccion:'', manzana:'',apPat:"",apMat:"",nombre:""})
  const{edo, muni, Seccion, manzana,apPat, apMat, nombre}=filtros
  
  useEffect(()=>{
    
    const veriSesion =  () => 
    { if(usu==null)
      {                                
        guardarUsuario([])						              
        localStorage.clear();           
        history.push("/")	
      }
      else
      { if (usuario.length===0) 
        { guardarUsuario({Nombre:usu,nivel:nvl});                                      
        }
        getListMuni(15) 
      }
       getListTotal()
    }
    veriSesion()    
    
    return ()=> {
      source.cancel();
    }
  },[])

/*   useEffect(()=>{
    if (edo) 
    { getListMuni(edo)             
    }
  },[edo]) */

  useEffect(() => {
    if (muni) 
    {
      getListSecc(muni)                 
    }
        
  }, [muni])
  
  useEffect(() => {
    if (Seccion) 
    {
      //getListManzana(Seccion)        
      //getListSudoDet2(Seccion)         
    }
        
  }, [Seccion])

 

  function getListMuni(auxEdo)  {            
    const url= `lugar-municipio`;                            
    const data = qs.stringify({                
      usuario:usuID, estado:auxEdo, idDispositivo:auxDispV,
    });
                
    function respuesta (aux) 
    { if (aux[0].Id != -1) 
      { setListMuni(aux)
      }  
      else{
        setListMuni([])
      }                                                                    
    }
    llamaApiCargaToken(data, url, respuesta, ErrorGeneral, setEsperaMuni, history, source);
  };

  const getListSecc = (auxMuni) => {
    const data = qs.stringify({
      usuario: usuID, municipio: auxMuni, idDispositivo: auxDispV,
    });
    const url = "lugar-seccion";

    function respuestaSecc(auxiliar) {
      if (auxiliar[0].Id != -1) {                
        setListSecc(auxiliar)
      }
    }

    llamaApiCargaToken(data, url, respuestaSecc, ErrorGeneral, setEsperaSecc, history, source);
  }

  const getListManzana = (auxSecc) => {
    const data = qs.stringify({
      estado:edo, municipio: muni, seccion:auxSecc,idDispositivo: auxDispV,
    });
    const url = "";
    const urlExt="https://cluster-bd.daduva.mx/api/lugar/v1/manzana"
    function respuestaSecc(auxiliar) {
      if (auxiliar[0].Id != -1) {                
        setListManzana(auxiliar)
      }
    }

    llamaApiCargaToken(data, url, respuestaSecc, ErrorGeneral, setEspera, history, source,urlExt);
  }

 

  const getListSudoDet = (auxSecc) => {
    const data = qs.stringify({
      Estado:edo,  
      Paterno:apPat,
      Materno:apMat,
      Nombre:nombre,
      idDispositivo: auxDispV,
    });
    const url = "";
    const urlExt="https://cluster-bd.daduva.mx/api/mx/v1/zudoku/search/name"
    function respuestaSecc(auxiliar) {
      if (auxiliar[0].Id != -1) {                
        setListSudoDet(auxiliar)
//        const auxTitulo=obtenTituloDir2(auxSecc)
      //  setTituloDirecc(auxTitulo)
        setRegisAgrup([])
      }
    }

    llamaApiCargaToken(data, url, respuestaSecc, ErrorGeneral, setEsperaList, history, source,urlExt);
  }
  
  const getListTotal = () => {
    const data = qs.stringify({
      tipo:2, llave:15, idDispositivo: auxDispV,
    });
    const url = "";
    const urlExt="https://cluster-bd.daduva.mx/api/mx/v1/zudoku"
    function respuestaSecc(auxiliar) {
      if (auxiliar[0].Id != -1) {                
        setListTotal(auxiliar)
      }
    }

    llamaApiCargaToken(data, url, respuestaSecc, ErrorGeneral, setEspera, history, source,urlExt);
  }

  const filtroSlct=(e)=>{
    let arr=[]
    switch (e.target.name) {             
      case 'edo':
        arr={...filtros, edo:e.target.value,
          muni:"", Seccion:'', manzana:''}
        setListSecc([])
        setListManzana([])
        break
      case 'muni':
        arr={...filtros, muni:e.target.value, Seccion:'', manzana:''}
        setListManzana([])
        break
      case 'Seccion':
        arr={...filtros, Seccion:e.target.value, manzana:''}
        break
      default:
        arr= {...filtros, [e.target.name]:e.target.value}
        break;
    }  
    setFiltros(arr)
  }

  const onChangeSlct=(e)=>{      
    e.preventDefault()      
    if (e!== null && e!== undefined) 
    {   filtroSlct(e) 
    }
    else
    {   setFiltros({...filtros,[e.target.name]:""})          
    }   
    setListSudoDet([])     
  }

  const onChangeNom = e =>
	{ let expNopermitida = new RegExp('[0-9$.@#_&-+()/:;!?,<>=%{}*|`]');  
    let expMenos = new RegExp("'");
    let expMas = new RegExp('"');             
    let expCadena = new RegExp('[A-Za-z]');  
    if ( ( expCadena.test(e.target.value) && !expNopermitida.test(e.target.value) 
      && !expMenos.test(e.target.value) && !expMas.test(e.target.value) ) 
      || e.target.value===' '|| e.target.value==='') 
    {
      setFiltros({...filtros,[e.target.name] : e.target.value})            
    }		
	}; 

  const obtenTituloDir=(auxMan)=>{
    let respuesta=""
    const filtrado=listSecc.filter(secc=>secc.Id===Seccion)
    respuesta=`Sección ${filtrado[0].Numero} Manzana ${auxMan}`
    return respuesta
  }

  const obtenTituloDir2=(auxSecc)=>{
    let respuesta=""
    const filtrado=listSecc.filter(secc=>secc.Id===auxSecc)
    respuesta=`Sección ${filtrado[0].Numero} `
    return respuesta
  }

  const valCad=(cadena,leyenda)=>{
    if (cadena.length>=3) {
      return true
    }
    else
    {
      const mensaje = `${leyenda} invalido, ingrese un ${leyenda} mayor a 3 caracteres`;
      toast.warning(mensaje,{
          pauseOnFocusLoss: false,                    
          toastId: `sudo-regis${1}`
      }) 
      return false
    }
  }
  const onClickRecargar=()=>{
    if (valCad(nombre,"Nombre") && valCad(apPat,"Apellido Paterno")  && valCad(apMat,"Apellido Materno")) {
      getListSudoDet()  
    }     
  }

  const slctMuni =                        
    <SelectList lista={listMuni} value={muni} onChange={onChangeSlct} 
      etqVal={"Id"} etqId="Id" etqAlias={"Nom"} etiq={"Municipio"} nameEtq={"muni"}
      estilo={{minWidth:18+'rem',marginLeft:".5rem"}} 
    /> 

  const slctSecc =                        
    <SelectList lista={listSecc} value={Seccion} onChange={onChangeSlct} 
      etqVal={"Id"} etqId="Id" etqAlias={"Numero"} etiq={"Sección"} nameEtq={"Seccion"}
      estilo={{minWidth:'8.5rem',marginLeft:".5rem"}} 
    />  

/*   const slctManzana =                        
    <SelectList lista={listManzana} value={manzana} onChange={onChangeSlct} 
      etqVal={"Nom"} etqId="Nom" etqAlias={"Nom"} etiq={"Manzana"} nameEtq={"manzana"}
      estilo={{minWidth:'8.5rem',marginLeft:".5rem"}} 
    />   */

  return (           
  <div style={{height:window.innerHeight-110 }} id="cajaSudo">
    
    <Box >
      <Typography style={{marginBottom: ".5rem",marginTop: "1rem"}}>  Estado de México Busqueda por Nombre</Typography>                  
      <Box display={"flex"}>
        <TextField required id="Nombre-input" onChange={onChangeNom} style={{paddingBottom:.5+'rem',width:10+'rem',marginRight:".5rem"}}
          label="Nombre" value={nombre} name='nombre'size="small" autoComplete='off' // defaultValue="Nombre"                
          //auxArrerror={arrError[3]} onBlur={()=>verifiNombre()}
        />
            
        <TextField required id="apPat-input" onChange={onChangeNom} style={{paddingBottom:.5+'rem',width:10+'rem',marginRight:".5rem"}}
          label="Apellido Paterno" value={apPat} name='apPat'size="small" autoComplete='off' // defaultValue="apPat"                
          //auxArrerror={arrError[3]} onBlur={()=>verifiNombre()}
        />

        <TextField required id="apMat-input" onChange={onChangeNom} style={{paddingBottom:.5+'rem',width:10+'rem'}}
          label="Apellido Materno" value={apMat} name='apMat'size="small" autoComplete='off' // defaultValue="apMat"                
          //auxArrerror={arrError[3]} onBlur={()=>verifiNombre()}
        />
        <IconButton color="primary"  aria-label="add circle" 
          component="span" onClick={onClickRecargar}>
          <Tooltip title="Reiniciar">
            <ReplayIcon/>
          </Tooltip>
        </IconButton>  
       { /*  <SelectEdo edo={edo} onChangeSlct={onChangeSlct} />      */ }
        {/* esperaMuni ? <Box width={"15rem"} mt={3}><LinearProgress/></Box> : (listMuni.length!==0 ? slctMuni :null) */}                      
        {/* esperaSecc ? <Box width={"15rem"} mt={3} ml={2}><LinearProgress/></Box> : (listSecc.length!==0 ?  slctSecc:null) */}
        {/* listManzana.length!==0 ? slctManzana:null */}
      </Box>
    </Box> 
    {esperaList?
      <Box width={"20rem"} mt={3}><LinearProgress/></Box>
    :
    (listSudoDet.length!==0 ?
      <Box display={"flex"}>
        {/* <TablaAgrupamiento listAv={listSudoDet} registro={regisAgrup}  setRegistro={setRegisAgrup} tituloDirecc={tituloDirecc}/>
         listSudoDet.length!==0 && regisAgrup.length!==0?
          <Box>
          
          </Box>
        :null */} 
          <TablaPromovidos listAv={listSudoDet} />
      </Box>
    :null)
    }
    
  </div>                           
  )
}

export default RepoSudoku