import React,{useState,useEffect} from 'react'
import {AuthContext} from '../context/AuthContext'
import {Box,LinearProgress,Breadcrumbs, Typography} from "@material-ui/core";
import Reporte from './Reporte'
import axios from 'axios';
import qs from 'qs';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from "react-router-dom";
import {llamaApiCargaToken, llamadaApiToken, ErrorGeneral } from '../funciones/ClienteAxios';
import SelectEdo from '../usuario/SelectEdo';
import { obtenDispo } from '../funciones/datosLocal';
import SelectList from '../generales/SelectList';
import TablaAgrupamiento from './TablaAgrupa';
import TablaPromovidos from './TablaPromov';
import TablaTotal from './TabEdoTot';
import Graficas from './Graficas';
import BusqSudoku from '../busqSudoku'

const useStyles = makeStyles({
 
  textoSelect: {
    '&:hover': {
      color:'#9ccdfa',
    },
    fontSize:1.25+'rem'
  },
});

const RepoSudoku = () => {
  let history = useHistory();    
  const classes = useStyles();
  const colFondo=process.env.REACT_APP_Fondo_Color
  const source = axios.CancelToken.source();  
  const usu= localStorage.getItem('UsuarioNom');
  const nvl= localStorage.getItem('Tipo');
  const usuID = localStorage.getItem('UsuId') ? parseInt(localStorage.getItem('UsuId')) : 0;
  const auxDispV =obtenDispo()
  const {usuario,guardarUsuario}=React.useContext(AuthContext);  
  const [datos,setDatos]= useState([])
  const [espera,setEspera]= useState(false)
  const [esperaList,setEsperaList]= useState(false)
  const [esperaMuni,setEsperaMuni]= useState(false)
  const [esperaSecc,setEsperaSecc]= useState(false)
  const [listMuni, setListMuni] = useState([]);
  const[listSecc, setListSecc] = useState([])    
  const[listManzana, setListManzana] = useState([])    
  const[listSudoDet, setListSudoDet] = useState([])
  const[listTotal, setListTotal] = useState([])    
  const [regisAgrup, setRegisAgrup] = useState([]);
  const [regisTot, setRegisTot] = useState([]);
  const [tituloDirecc, setTituloDirecc] = useState("");
  const [opcion,setOpcion] = useState(1)
  const [filtros,setFiltros]= useState({edo:'', muni:'', Seccion:'', manzana:''})
  const{edo, muni, Seccion, manzana}=filtros
  
  useEffect(()=>{
    
    const veriSesion =  () => 
    { if(usu==null)
      {                                
        guardarUsuario([])						              
        localStorage.clear();           
        history.push("/")	
      }
      else
      { if (usuario.length===0) 
        { guardarUsuario({Nombre:usu,nivel:nvl});                                      
        }
      }
      getListTotal()
    }
    veriSesion()    
    
    return ()=> {
      source.cancel();
    }
  },[])

  useEffect(()=>{
    if (edo) 
    { getListMuni(edo)             
    }
  },[edo])

  useEffect(() => {
    if (muni) 
    {
      getListSecc(muni)                 
    }
        
  }, [muni])
  
  useEffect(() => {
    if (Seccion) 
    {
      //getListManzana(Seccion)        
      getListSudoDet2(Seccion)         
    }
        
  }, [Seccion])

  useEffect(() => {
    if (manzana) 
    {
      getListSudoDet(manzana)                 
    }
        
  }, [manzana])

  function getListMuni(auxEdo)  {            
    const url= `lugar-municipio`;                            
    const data = qs.stringify({                
      usuario:usuID, estado:auxEdo, idDispositivo:auxDispV,
    });
                
    function respuesta (aux) 
    { if (aux[0].Id != -1) 
      { setListMuni(aux)
      }  
      else{
        setListMuni([])
      }                                                                    
    }
    llamaApiCargaToken(data, url, respuesta, ErrorGeneral, setEsperaMuni, history, source);
  };

  const getListSecc = (auxMuni) => {
    const data = qs.stringify({
      usuario: usuID, municipio: auxMuni, idDispositivo: auxDispV,
    });
    const url = "lugar-seccion";

    function respuestaSecc(auxiliar) {
      if (auxiliar[0].Id != -1) {                
        setListSecc(auxiliar)
      }
    }

    llamaApiCargaToken(data, url, respuestaSecc, ErrorGeneral, setEsperaSecc, history, source);
  }

  const getListManzana = (auxSecc) => {
    const data = qs.stringify({
      estado:edo, municipio: muni, seccion:auxSecc,idDispositivo: auxDispV,
    });
    const url = "";
    const urlExt="https://cluster-bd.daduva.mx/api/lugar/v1/manzana"
    function respuestaSecc(auxiliar) {
      if (auxiliar[0].Id != -1) {                
        setListManzana(auxiliar)
      }
    }

    llamaApiCargaToken(data, url, respuestaSecc, ErrorGeneral, setEspera, history, source,urlExt);
  }

  const getListSudoDet = (auxManzana) => {
    const data = qs.stringify({
      estado:edo, seccion:Seccion, manzana:auxManzana,idDispositivo: auxDispV,
    });
    const url = "";
    const urlExt="https://cluster-bd.daduva.mx/api/mx/v1/zudoku/detalle"
    function respuestaSecc(auxiliar) {
      if (auxiliar[0].Id != -1) {                
        setListSudoDet(auxiliar)
        const auxTitulo=obtenTituloDir(auxManzana)
        setTituloDirecc(auxTitulo)
        setRegisAgrup([])
      }
    }

    llamaApiCargaToken(data, url, respuestaSecc, ErrorGeneral, setEsperaList, history, source,urlExt);
  }

  const getListSudoDet2 = (auxSecc) => {
    const data = qs.stringify({
      estado:edo, seccion:auxSecc, idDispositivo: auxDispV,
    });
    const url = "";
    const urlExt="https://cluster-bd.daduva.mx/api/mx/v1/zudoku/detalle"
    function respuestaSecc(auxiliar) {
      if (auxiliar[0].Id != -1) {                
        setListSudoDet(auxiliar)
        const auxTitulo=obtenTituloDir2(auxSecc)
        setTituloDirecc(auxTitulo)
        setRegisAgrup([])
      }
    }

    llamaApiCargaToken(data, url, respuestaSecc, ErrorGeneral, setEsperaList, history, source,urlExt);
  }

  const getListTotal = () => {
    const data = qs.stringify({
      tipo:1, llave:0, idDispositivo: auxDispV,
    });
    const url = "";
    const urlExt="https://cluster-bd.daduva.mx/api/mx/v1/zudoku"
    function respuestaSecc(auxiliar) {
      if (auxiliar[0].Id != -1) {                
        setListTotal(auxiliar)
      }
    }

    llamaApiCargaToken(data, url, respuestaSecc, ErrorGeneral, setEspera, history, source,urlExt);
  }

  const filtroSlct=(e)=>{
    let arr=[]
    switch (e.target.name) {             
      case 'edo':
        arr={...filtros, edo:e.target.value,
          muni:"", Seccion:'', manzana:''}
        setListSecc([])
        setListManzana([])
        break
      case 'muni':
        arr={...filtros, muni:e.target.value, Seccion:'', manzana:''}
        setListManzana([])
        break
      case 'Seccion':
        arr={...filtros, Seccion:e.target.value, manzana:''}
        break
      default:
        arr= {...filtros, [e.target.name]:e.target.value}
        break;
    }  
    setFiltros(arr)
  }

  const onChangeSlct=(e)=>{      
    e.preventDefault()      
    if (e!== null && e!== undefined) 
    {   filtroSlct(e) 
    }
    else
    {   setFiltros({...filtros,[e.target.name]:""})          
    }   
    setListSudoDet([])     
  }

  const obtenTituloDir=(auxMan)=>{
    let respuesta=""
    const filtrado=listSecc.filter(secc=>secc.Id===Seccion)
    respuesta=`Sección ${filtrado[0].Numero} Manzana ${auxMan}`
    return respuesta
  }

  const obtenTituloDir2=(auxSecc)=>{
    let respuesta=""
    const filtrado=listSecc.filter(secc=>secc.Id===auxSecc)
    respuesta=`Sección ${filtrado[0].Numero} `
    return respuesta
  }

  const slctMuni =                        
    <SelectList lista={listMuni} value={muni} onChange={onChangeSlct} 
      etqVal={"Id"} etqId="Id" etqAlias={"Nom"} etiq={"Municipio"} nameEtq={"muni"}
      estilo={{minWidth:18+'rem',marginLeft:".5rem"}} 
    /> 

  const slctSecc =                        
    <SelectList lista={listSecc} value={Seccion} onChange={onChangeSlct} 
      etqVal={"Id"} etqId="Id" etqAlias={"Numero"} etiq={"Sección"} nameEtq={"Seccion"}
      estilo={{minWidth:'8.5rem',marginLeft:".5rem"}} 
    />  

  const selectOpcion =(e,auxValor)=>{
    e.preventDefault();        
    setOpcion(auxValor)
  }
  const menu=(
    <Box display="flex" justifyContent="center">
      <Breadcrumbs separator="-" aria-label="breadcrumb"  >
        <Typography variant="h5" color={opcion === 1 ? "secondary":'textPrimary'} 
          className={classes.textoSelect} onClick={e=>selectOpcion(e,1)}
        >
          Nombre 
        </Typography>
        <Typography variant="h5" color={opcion === 2 ? "secondary":'textPrimary'} 
          className={classes.textoSelect} onClick={e=>selectOpcion(e,2)}
        >
          Sección
        </Typography>    
        
      </Breadcrumbs>
    </Box>
  )

  return (           
  <div style={{height:window.innerHeight-110 }} id="cajaSudo">
    <Box mb={1} display={"flex"} flexDirection={"row"}>
      {espera ?
      <Box width={"20rem"} mt={3}><LinearProgress/></Box>
      :
      (listTotal.length !== 0 ?
        <TablaTotal listAv={listTotal} registro={regisTot}  setRegistro={setRegisTot} />
      :null)}
      {listTotal.length !== 0 ?
        <Box>
          <Graficas valor={listTotal} />
        </Box>
      :null}
    </Box>
    {menu}
    { opcion === 2?
      <span>
      <Box >
      <Typography style={{marginBottom: ".5rem",marginTop: "1rem"}}>  Busqueda por Secciones </Typography>                  
      <Box display={"flex"}>
        <SelectEdo edo={edo} onChangeSlct={onChangeSlct} />      
        {esperaMuni ? <Box width={"15rem"} mt={3}><LinearProgress/></Box> : (listMuni.length!==0 ? slctMuni :null)}                      
        {esperaSecc ? <Box width={"15rem"} mt={3} ml={2}><LinearProgress/></Box> : (listSecc.length!==0 ?  slctSecc:null)}
     
      </Box>
    </Box> 
    {esperaList?
      <Box width={"20rem"} mt={3}><LinearProgress/></Box>
    :
    (listSudoDet.length!==0 ?
      <Box display={"flex"}>
        <TablaAgrupamiento listAv={listSudoDet} registro={regisAgrup}  setRegistro={setRegisAgrup} tituloDirecc={tituloDirecc}/>
        {listSudoDet.length!==0 && regisAgrup.length!==0?
          <Box>
            <TablaPromovidos listAv={regisAgrup} />
          </Box>
        :null}
      </Box>
    :null)
    }    
    </span>
    :<BusqSudoku/>}
  </div>                           
  )
}

export default RepoSudoku