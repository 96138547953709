import {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import { Box, IconButton, LinearProgress, Paper,
    Table, TableBody, TableCell, TableContainer, TableHead,
    TableRow, Tooltip,} from '@material-ui/core';
import qs from 'qs';
import moment from 'moment';
import { celda, } from "../generales/TablaGeneral";
import {ErrorGeneral, llamadaApiToken} from '../funciones/ClienteAxios';
import LocationOnIcon from "@material-ui/icons/LocationOn";
import ModalMapa from "./ModalMapa";
import { obtenIdUsu} from '../funciones/datosLocal';
import CreateIcon from '@material-ui/icons/Create';
import ModalEditRecorrido from "./ModNvoEditRecorrido";

const useStyles = makeStyles(theme=>({
    container: {
        maxHeight: window.innerHeight - 170,
        minHeight: 280,
        minWidth: '100%',
        //maxWidth:"60rem"
    },
    container2: {
        maxHeight: 550,
    },
    tableRow: {
        "&$selected, &$selected:hover": {
            backgroundColor: theme.palette.secondary.main
        }
    },
    tableCell: {
        "$selected &": {
            color: "white"
        }
    },
    hover: {},
    selected: {}
}));

export default function TablaRecorridos({ history, fechasSeleccionadas, selectedID, setSelectedID, source, buscar,
                                            checked, abrirModal, setAbrirModal, setRecorrido, }) {
    const classes = useStyles();
    const idusu = obtenIdUsu();
    const [espera, setEspera] = useState(false);
    const [datos, guardarDatos] = useState([])
    const [sinDatos, guardarSinDatos] = useState(true)
    const [open, setOpen] = useState(false);
    const [seleccionado, setSeleccionado] = useState([]);
    const [modalEdit, setModalEdit] = useState(false);
    const [recarga, setRecarga] = useState(false);
    let fInicio = moment(fechasSeleccionadas.inicio).format("YYYY-MM-DD")
    let fFinal = moment(fechasSeleccionadas.final).format("YYYY-MM-DD")
    let filter = 0


    useEffect(() => {
        const llenarTabla = () => {
            setEspera(true)
            if (checked) {
                filter = idusu
            } else {
                filter = 0
            }

            let data = qs.stringify({
                Usuario: idusu,
                Filtro: 0,
                Fini: fInicio + 'T00:00:00',
                Ffin: fFinal + 'T23:59:59',
                idDispositivo: '45vf2d1vdf21d2f'
            });
            let url = "recorrido-list";

            function response(data) {
                if (data[0].Id != -1) {
                    guardarSinDatos(true)
                    guardarDatos(data)
                    setEspera(false)
                } else {
                    guardarSinDatos(false)
                    setEspera(false)
                }
            }

            llamadaApiToken(data, url, response, ErrorGeneral, history, source);
        };

        llenarTabla();
    }, [buscar,recarga]);

    const seleccionar = (auxId, auxOrden) => {
        setSelectedID(auxId)
        setRecorrido(auxOrden)
        setSeleccionado(auxOrden)
    }

    const abrirModalMapa = () => {
        setAbrirModal(true)
    }

    const modalMapa = abrirModal ? <ModalMapa abrirModal={abrirModal} setAbrirModal={setAbrirModal}  datos={seleccionado}/> : null

    function verMas(auxId) {
        setSelectedID(auxId)
        if (auxId === selectedID) {
            setOpen(!open)
        } else {
            setOpen(false)
        }
    }    

    function Row(props) {
        const {row} = props;
        return (        
        <TableRow onClick={() => { seleccionar(row.Id, row) }}
            className={classes.tableRow} selected={selectedID === row.Id}
            classes={{hover: classes.hover, selected: classes.selected}}
        >     
             {idusu ===1 ?
             <TableCell className={classes.tableCell} align="center" padding={'none'} width={65}
             style={{fontSize: 12 + 'px'}}  >
                {row.Id}
            </TableCell>
            :null}       
            {row.Lat && row.Lon !== 0.0 ?
                <TableCell align="center" padding={'none'} style={{width: 30}}>
                    <Tooltip title={'Ver más'}>
                        <IconButton color="primary" size="small"
                            onClick={() => abrirModalMapa()}>
                            {selectedID === row.Id ?
                                <LocationOnIcon fontSize="small" style={{color: 'white'}}/> 
                                : <LocationOnIcon fontSize="small" />}
                        </IconButton>
                    </Tooltip>
                </TableCell> 
                : <TableCell align="center" padding={'none'} style={{width: 30}}></TableCell>
            }
            {idusu ===1 ?
             <TableCell className={classes.tableCell} align="center" padding={'none'} width={65}
             style={{fontSize: 12 + 'px'}}  >
               <Tooltip title={'Editar Recorrido'}>
                    <IconButton color="primary" 
                        onClick={() => setModalEdit(true)}>                            
                            <CreateIcon fontSize="small" color={selectedID === row.Id ?"inherit":"primary"}/>                                 
                    </IconButton>
                    </Tooltip>
            </TableCell>
            :null}  
            <TableCell className={classes.tableCell} align="center" padding={'none'} width={75}
                style={{fontSize: 12 + 'px'}} >
                {moment.utc(row.FReg).local(true).format("DD/MM/YYYY HH:mm")}
            </TableCell>
            <TableCell className={classes.tableCell} align="center" padding={'none'} width={75}
                style={{fontSize: 12 + 'px'}} >
                {moment.utc(row.FEnv).local(true).format("DD/MM/YYYY HH:mm")}
            </TableCell>
            <TableCell className={classes.tableCell} align="left" padding={'none'} width={180}
                style={{fontSize: 12 + 'px'}} >
                {row.UsrNom}
            </TableCell>
            <TableCell className={classes.tableCell} align="center" padding={'none'} width={120} >
                {row.TipoNom}
            </TableCell>
          {/*   <TableCell className={classes.tableCell} align="center" padding={'none'} width={80}
                style={{fontSize: 12 + 'px'}} >
                {row.EdoNom}
            </TableCell>
            <TableCell className={classes.tableCell} align="center" padding={'none'} width={100}
                style={{fontSize: 10 + 'px'}} >
                {row.MuniNom}
            </TableCell> */}
            <TableCell className={classes.tableCell} align="center" padding={'none'} width={55} >
                {row.SeccNom}
            </TableCell>
            <TableCell className={classes.tableCell} align="left" padding={'none'} style={{fontSize: 12 + 'px'}}
                    width={250}>
                {row.Direcc}
            </TableCell>
            {celda( "left", {width:"150px",fontSize:"12px"}, row.Obsrv, 11, classes.tableCell)}
        </TableRow>                    
        );
    }

    function tablaCompleta(auxlista) {
        return (
        <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
            <TableHead>
                <TableRow>                      
                    {idusu ===1 ?
                    <TableCell align="center" padding='none'></TableCell>                            
                    :null}
                    {idusu ===1 ?
                    <TableCell align="center" padding='none'></TableCell>                            
                    :null}
                    <TableCell align="center" padding='none'>{auxlista.length}</TableCell>                            
                    <TableCell align="center" padding='none'>Fecha Sistema</TableCell>
                    <TableCell align="center" padding='none'>Fecha Registro</TableCell>
                    <TableCell align="center" padding='none'>Nombre</TableCell>
                    <TableCell align="center" padding='none'>Tipo</TableCell>
                   {/*  <TableCell align="center" padding='none'>Estado</TableCell>
                    <TableCell align="center" padding='none'>Municipio</TableCell> */}
                    <TableCell align="center" padding='none'>Sección</TableCell>
                    <TableCell align="center" padding='none'>Dirección</TableCell>
                    <TableCell align="center" padding='none'>Observación</TableCell>  
                </TableRow>
            </TableHead>
            <TableBody>
                {auxlista.length != 0 ? (auxlista.map((row) => (
                    <Row key={row.Id} row={row}/>
                ))) : null}
            </TableBody>
        </Table>
        </TableContainer>
        )
    }

    const tablaSinDatos = () => {
        return (
            <TableContainer className={classes.container2}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center" padding='none'></TableCell>
                            <TableCell align="center" padding='none'>Fecha Sistema</TableCell>
                            <TableCell align="center" padding='none'>Fecha Registro</TableCell>
                            <TableCell align="center" padding='none'>Nombre</TableCell>
                            <TableCell align="center" padding='none'>Tipo</TableCell>
                            <TableCell align="center" padding='none'>Estado</TableCell>
                            <TableCell align="center" padding='none'>Municipio</TableCell>
                            <TableCell align="center" padding='none'>Sección</TableCell>
                            <TableCell align="center" padding='none'>Dirección</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow className={classes.tableRow}>
                            <TableCell className={classes.tableCell} align="center">Dia</TableCell>
                            <TableCell className={classes.tableCell} align="left">Sin</TableCell>
                            <TableCell className={classes.tableCell} align="left">Recorridos</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        )
    }

    const modEditarRecor = modalEdit ? <ModalEditRecorrido abrirModal={modalEdit} registro={seleccionado}
                                            setAbrirModal={setModalEdit} latitud={0}
                                            longitud={0} actualiza={()=>setRecarga(!recarga)} titulo={`Editar Registro ${seleccionado.Id}`}
                                        /> : null

    const contenido = sinDatos && datos.length != 0 ? tablaCompleta(datos) : tablaSinDatos()
    const tabla = espera ? (<Box mt={2}><LinearProgress/> </Box>) : (contenido)

    return (
    <Paper>
        {modEditarRecor}
        {tabla}
        {modalMapa}
    </Paper>
    );
}