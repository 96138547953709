import {makeStyles} from "@material-ui/core/styles";
import Modal from "../generales/ModalCerrarActualiza";
import {useEffect, useState} from "react";
import {FormControl, Grid, InputLabel, LinearProgress, MenuItem,
     Select, TextField, FormControlLabel, Checkbox,} from "@material-ui/core";
import qs from "qs";
import {ErrorGeneral, llamaApiCargaToken,} from "../funciones/ClienteAxios";
import Autocomplete from "@material-ui/lab/Autocomplete";
import moment from "moment/moment";
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import esLocale from "date-fns/locale/es";
import {toast} from "react-toastify";
import { obtenDispo,obtenIdUsu} from '../funciones/datosLocal';
import {useHistory} from "react-router-dom";
import axios from "axios";
import SelectEdo from '../generales/SelectEdo';

const useStyles = makeStyles((theme) => ({}));

const ModalNvoEditRecorrido = ({abrirModal, setAbrirModal, latitud, longitud, actualiza, registro,titulo}) => {

    const classes = useStyles();
    const usuTipo = localStorage.getItem('Tipo');
    let fecha = Date.now()
    const auxDispV =obtenDispo()
    const idusu = obtenIdUsu();
    const history = useHistory();
    const tipoNacion=process.env.REACT_APP_Nvl 
    const source = axios.CancelToken.source();
    const [selectedDate, handleDateChange] = useState(fecha);
    const [listMun, setListMun] = useState([])
    const [listSecc, setListSecc] = useState([])    
    const [listTipo, setListTipo] = useState([])
    const [nvoRegistro, setNvoRegistro] = useState({ edo:'', Municipio: '', Seccion: '', Tipo: '',
        Latitud: latitud, Longitud: longitud, Fecha: '', Direccion: '', Observacion: '', Repetido:false
    });
    const {edo, Municipio, Seccion, Tipo, Longitud, Latitud, Fecha, Direccion, Observacion,Repetido} = nvoRegistro
    const [btnDeshabilitado, setBotonDeshabilitado] = useState(false)
    const [aviso, setAviso] = useState(false);
    const [mensaje, setMensaje] = useState("");
    const [tipoAdvertencia, setTipoAdvertencia] = useState("success");
    const [cargando, setCargando] = useState(false); 

    useEffect(() => {                        
         console.log(registro);
        if (registro) {
            getMunicipios(registro.EdoId,1)
            getSecciones(0,registro.SeccId)
        }        
                    
        
        if (listTipo.length === 0) {
            recuperarTipo()
        }

        return ()=> {
            source.cancel();
        }  

    }, []); 

    useEffect(()=>{
        if (edo) {
            getMunicipios(edo,2)        
        }
    },[edo])

    useEffect(() => {
        if (Municipio) {
            getSecciones(2,Municipio.Id)                     
        }
            
    }, [Municipio])

    const recuperarTipo = () => {
        const data = qs.stringify({
            Usuario: idusu, idDispositivo: auxDispV
        });
        const url = "recorrido-tipo";

        function respuesta(response) {
            if (response[0].Id != -1) {
                setListTipo(response)
            }
        }

        llamaApiCargaToken(data, url, respuesta, ErrorGeneral, setCargando, history, source);
    };

    const getSecciones = (auxTipo,auxMuni) => {
        const data = qs.stringify({
            usuario: idusu, 
            municipio: auxMuni,
            idDispositivo:auxDispV,
        });
        const url = "lugar-seccion";

        function respuesta(response) {
            if (response[0].Id != -1) {
               /*  if (registro && auxTipo===1) {
                    
                    const seccFilt = response.filter((auxSeccion) => auxSeccion.MuniId === registro.MuniId)                    
                 
                } */
                setListSecc(response)    
            }
        }

        llamaApiCargaToken(data, url, respuesta, ErrorGeneral, setCargando, history, source);
    };

    const getMunicipios = (auxEdo,auxTipo) => {
        const data = qs.stringify({
            usuario: idusu, estado:auxEdo !==0? auxEdo:process.env.REACT_APP_EDO,
            tipo: usuTipo, idDispositivo:auxDispV,
        });
        const url = "lugar-municipio-tipo";

        function respuesta(response) {
            if (response[0].Id != -1) {
                setListMun(response)
                if (registro && auxTipo===1) {
                    const { EdoId,  MuniId, SeccId, 
                    TipoId,  Direcc, Obsrv}=registro
                    const filtro=response.filter((muni)=>muni.Id === MuniId)                                   
                    setNvoRegistro({...nvoRegistro,
                        edo:EdoId, Municipio: filtro[0],
                        Seccion: SeccId, 
                        Tipo: TipoId,                                          
                        Direccion: Direcc,
                        Repetido:registro.Rep===1?true:false,
                        Observacion: Obsrv})
                }
                
            }
        }

        llamaApiCargaToken(data, url, respuesta, ErrorGeneral, setCargando, history, source);
    };

    const handleClose = () => {
        setAbrirModal(false)
        setAviso(false);
    }

    const guardarMuni = (e) => {
        if (e !== null && e !== undefined) {
            
            setNvoRegistro({
                ...nvoRegistro,
                Municipio: e,
                Seccion: ""
            })
            setMensaje('');
            setAviso(false);
        } else {
            setNvoRegistro({
                ...nvoRegistro,
                Municipio: "", Seccion: ""
            })
          
        }
    }

    const guardarSeccionSelect = (e) => {
        if (e !== null && e !== undefined) {
            setNvoRegistro({
                ...nvoRegistro,
                Seccion: e.target.value
            })
            setMensaje('');
            setAviso(false);
        } else {
            setNvoRegistro({
                ...nvoRegistro,
                Seccion: ""
            })
        }
    }

    const onChange = e => {
        setNvoRegistro({
            ...nvoRegistro,
            [e.target.name]: e.target.value
        })
    }

    const getNvoRecorrido = () => {        

        const data = qs.stringify({
            Usuario: idusu,
            Edo: tipoNacion==="muni"? process.env.REACT_APP_EDO:edo,
            Muni: Municipio.Id,
            Secc: Seccion,
            fecha: moment(selectedDate).format("YYYY-MM-DD") + 'T00:00:00',
            latitud: Latitud,
            longitud: Longitud,
            Tipo: Tipo,
            Direccion: Direccion,
            Observ: Observacion,
            idDispositivo:auxDispV
        });

        const url = "recorrido-nuevo";

        function respuesta(response) {
            if (response.respuesta === 1) {
                setAviso(true)
                toast.success('Recorrido agregado correctamente', {
                    pauseOnFocusLoss: false,
                    toastId: `nvo-recorrido${1}`
                })
                actualiza()
                setAbrirModal(false)
            } else {
               // setBotonDeshabilitado(false);
                toast.error('Datos incorrectos, intente mas tarde', {
                    pauseOnFocusLoss: false,
                    toastId: `error-recorrido${1}`
                })
            }
        }

        llamaApiCargaToken(data, url, respuesta, ErrorGeneral, setCargando, history, source);
    }

    const getEditRecorrido = () => {        

        const data = qs.stringify({      
            Llave:registro.Id,
            Usuario:idusu,
            Edo:tipoNacion==="muni"? process.env.REACT_APP_EDO:edo,
            Muni:Municipio.Id,
            Secc:Seccion,
            Repetido:Repetido?1:0,
            Direccion:Direccion.trim(),
            Observ:Observacion.trim(),
            idDispositivo:auxDispV
        });

        const url = "recorrido/v2/edit";

        function respuesta(response) {
            if (response.respuesta === 1) {
                setAviso(true)
                toast.success('Recorrido editado correctamente', {
                    pauseOnFocusLoss: false,
                    toastId: `edit-recorrido${registro.Id}`
                })
                actualiza()
                setAbrirModal(false)
            } else {               
                toast.error('Datos incorrectos, intente mas tarde', {
                    pauseOnFocusLoss: false,
                    toastId: `edit-recorrido${registro.Id}`
                })
            }
        }

        llamaApiCargaToken(data, url, respuesta, ErrorGeneral, setCargando, history, source);
    }

    const verificaNulos = (campo,descripcion)=>{
        let respuesta =false;        
          
        if( (typeof campo !== 'number' && typeof campo === 'string' 
            && (campo.trim() === '' || campo.trim() === null) )
        ) 
        {   respuesta=true;        
          //  console.log(campo+' '+descripcion);                  
            setMensaje('No se lleno el campo '+descripcion);
            setTipoAdvertencia("warning")  
            setAviso(respuesta);
        }
        return(respuesta);
    }

    const onChangeRepe =() =>
    {  
        setNvoRegistro({ ...nvoRegistro,Repetido:!Repetido })        
    };

    const onChangeSlct=(e)=>{      
        e.preventDefault()      
        if (e!== null && e!== undefined) 
        {   setNvoRegistro({...nvoRegistro,[e.target.name]:e.target.value}) 
        }
        else
        {   setNvoRegistro({...nvoRegistro,[e.target.name]:""})          
        }                
    }

    function guardarDatos(e) {
        e.preventDefault()
        if ( !verificaNulos(edo,"Estado") && !verificaNulos(Municipio,"Municipio") && !verificaNulos(Seccion,"Sección")  && 
            !verificaNulos(Tipo,"Tipo") && !verificaNulos(Direccion,"Dirección") ) 
        {   //console.log("prueba",nuevoRegistro);
            setAviso(false);
            if (registro) {
                getEditRecorrido()
            }
            else{
                getNvoRecorrido()    
            }
                   
        }  
    }    

    return (
    <Modal
        titulo={titulo} modalAbierto={abrirModal} mensaje={mensaje}
        setModalAbierto={setAbrirModal} guardarDatos={guardarDatos}
        tipoAdvertencia={tipoAdvertencia} aviso={aviso} tamanio={'sm'}
        btnDeshabilitado={btnDeshabilitado} setAviso={setAviso}
        cargando={cargando} cerrarBorrar={handleClose}
    >
        <Grid container alignItems={'center'} spacing={1}>
           {tipoNacion === "Nacion" || tipoNacion === "Edo" ? 
            <Grid item xs={5}>
                {/* <FormControl size="small">
                    <InputLabel htmlFor="edo">edo</InputLabel>
                    <Select native value={edo}  
                        fullWidth onChange={onChange}
                        disabled={!registro ? false:tipoNacion==="Nacion"||tipoNacion==="Edo" ?false:true}
                        inputProps={{name: 'edo', id: 'edo',}}                        
                    >
                        <option value={process.env.REACT_APP_EDO}>Puebla</option>
                    </Select>
                </FormControl> */}
                <SelectEdo edo={edo} onChangeSlct={onChangeSlct} tipo={"outlined"}/>                    
            </Grid> 
            :null}
           
           <Grid item xs={7}>                
              {listMun.length !== 0 ?   <Autocomplete
                    id="muni-select-nvoPerfil" size='small' fullWidth 
                    disabled={tipoNacion === "muni" ?true:false}
                    onChange={(e, option) => guardarMuni(option)} value={Municipio}
                    getOptionLabel={(option) => option.Nom} name="Municipio"
                    noOptionsText={"Sin coincidencias"} options={listMun}
                    renderInput={(params) => (
                        <TextField {...params} size='small'
                            label="Municipio" variant="outlined"/>
                    )}
                />    : null}                 
            </Grid>
           
           
             <Grid item xs={4}>
                 {listSecc.length !== 0 ?
                    <FormControl size='small'>
                        <InputLabel size='small' variant="outlined" id="Seccion-label-nvo">Sección</InputLabel>
                        <Select size='small' variant="outlined" style={{width: '7rem'}}
                            label="Sección" id="Seccion-select2" name="Seccion"
                            value={Seccion} onChange={guardarSeccionSelect}
                            fullWidth
                        >
                            {listSecc.map((seccion, index) => {
                                return (
                                <MenuItem size='small' value={seccion.Id} key={seccion.Id}>
                                    <em>{seccion.Numero}</em>
                                </MenuItem>
                                )
                                }
                            )}
                        </Select>
                    </FormControl>      
                :  null }           
            </Grid>    
           
            <Grid item xs={4}>
                {listTipo.length !== 0 ?
                <FormControl size="small" disabled={registro?true:false}>
                    <InputLabel htmlFor="Tipo">Tipo</InputLabel>
                    <Select native value={Tipo} fullWidth onChange={onChange}
                        inputProps={{name: 'Tipo', id: 'Tipo',}} 
                    >
                        {listTipo.map(element => {
                            return <option key={element.Id} value={element.Id}>
                                {element.Nom}</option>
                        })}
                    </Select>
                </FormControl>
                : null
                }
            </Grid>
            <Grid item xs={4}>
                <MuiPickersUtilsProvider locale={esLocale} utils={DateFnsUtils}>
                    <DatePicker
                        disableFuture label="Fecha" disabled={registro?true:false}
                        format="dd/MM/yyyy" size="small"
                        views={["year", "month", "date"]}                                                
                        minDate="2017-03-14" maxDate={new Date()}
                        value={selectedDate} onChange={handleDateChange}
                        style={{width: 6.5 + 'rem'}}
                    />
                </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={12} style={{marginBottom:.5+'rem',marginTop:.5+'rem'}}>
                <TextField id="Direccion" name="Direccion" label="Dirección" fullWidth size="small"
                    multiline maxRows={2}
                    value={Direccion} onChange={onChange} variant="outlined"/>
            </Grid>
            <Grid item xs={12}>
                <TextField
                    id="Observacion" name="Observacion"
                    label="Observación" variant="outlined"
                    multiline fullWidth onChange={onChange}
                    minRows={4} value={Observacion}
                />
            </Grid>
            {registro?
            <Grid>                
                <FormControlLabel  style={{marginLeft:".5rem"}}
                    label="Repetido" size={'small'}
                    control={<Checkbox color="primary" checked={Repetido} name={"fecha"}/>}                
                    labelPlacement="end"  onChange={onChangeRepe}
                />
            </Grid>
            :null}
        </Grid>
    </Modal>);
};

export default ModalNvoEditRecorrido;
